import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  style: {"width":"1164px","max-width":"80vw","height":"80vh"},
  class: "relative"
}
const _hoisted_2 = { class: "sm:mx-4 sm:my-4 flex flex-col sm:flex-row gap-y-2" }
const _hoisted_3 = {
  key: 1,
  class: "pb-2 overflow-auto md:px-4",
  style: {"height":"60vh"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_time_range_picker = _resolveComponent("time-range-picker")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    modelValue: _ctx.isShowTransactionHistory,
    persistent: true,
    title: `Riwayat Transaksi Top Up`,
    class: "topup-manual-detail",
    "onUpdate:modelValue": _cache[2] || (_cache[2] = 
      () => {
        _ctx.showModalRequestTopupManualList(false);
        _ctx.resetFilterStatus('');
      }
    )
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_Select, {
            dataSelect: _ctx.statusData,
            onOpenSelect: _ctx.onOpenStatusSelect,
            onCloseSelect: _ctx.onCloseStatusSelect,
            onChooseSelect: _ctx.onSelectStatus,
            value: _ctx.statusName,
            isOpenMenu: _ctx.statusSelect,
            isDefaultBlack: "",
            class: "text-12px xxl:text-14px z-20",
            style: {"width":"14rem"},
            longTrim: 30
          }, null, 8, ["dataSelect", "onOpenSelect", "onCloseSelect", "onChooseSelect", "value", "isOpenMenu"]),
          _createVNode(_component_time_range_picker, {
            onSelect: _ctx.setPeriode,
            default: "30 hari terakhir"
          }, null, 8, ["onSelect"])
        ]),
        (_ctx.transactionHistoryList.length === 0 && _ctx.firstRequest && !_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_EmptyState, {
              key: 0,
              customMessage: "Oops.. Belum ada data yang dapat ditampilkan"
            }))
          : (_openBlock(), _createBlock("div", _hoisted_3, [
              _createVNode(_component_TableV2, {
                columns: _ctx.columns,
                data: _ctx.transactionHistoryList,
                loading: _ctx.isLoading,
                onRequest: _ctx.fetchTransactionHistoryList,
                errorCause: _ctx.errorCause,
                isDetailAble: "",
                onClick: _ctx.onClickRow,
                pagination: _ctx.pagination,
                "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
                paginationWidth: "w-7/8",
                pinnedSubstractHeight: "270px",
                isRightFreeze: true,
                totalColumnFreeze: _ctx.isMobile ? 0 : 2,
                totalPercentFreeze: 20
              }, null, 8, ["columns", "data", "loading", "onRequest", "errorCause", "onClick", "pagination", "totalColumnFreeze"])
            ]))
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}