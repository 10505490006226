
import { Vue, Options } from "vue-class-component";
import { BalanceController } from "@/app/ui/controllers/BalanceController";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import formatPriceRP from "@/app/infrastructures/misc/common-library/FormatPriceRP";
import { TransactionHistoryData } from "@/domain/entities/Balance";
import { ALLOWED } from "@/app/infrastructures/misc/RolePermission";

@Options({
  computed: {
    ableToViewDetail() {
      return checkRolePermission(ALLOWED);
    }
  }
})
export default class ModalListTopupHistory extends Vue {
  created() {
    this.initDataTable();
  }

  initDataTable() {
    BalanceController.initDataTableTransactionHistoryList();
  }

  fetchTransactionHistoryList() {
    BalanceController.fetchTransactionHistoryList();
  }

  get isMobile() {
    return window.screen.width < 768;
  }

  get firstRequest() {
    return BalanceController.firstRequest;
  }

  get transactionHistoryList() {
    return BalanceController.transactionHistoryList.data;
  }

  get isLoading() {
    return BalanceController.isLoading;
  }

  get errorCause() {
    return BalanceController.errorCause;
  }

  get pagination() {
    return BalanceController.transactionHistoryList.pagination;
  }

  onClose() {
    BalanceController.setShowTopupTransactionHistory(false);
  }

  // Filter Status
  statusName = "";
  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Pending", value: "pending" },
    { name: "Verified", value: "verified" },
    { name: "Approved", value: "approved" },
    { name: "Rejected", value: "rejected" },
    { name: "Verification Rejected", value: "verification_rejected" }
  ];

  statusSelect = false;

  onOpenStatusSelect() {
    this.statusSelect = true;
  }

  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  onSelectStatus(name: string, value: string) {
    this.statusName = name;
    this.onCloseStatusSelect();
    BalanceController.setStatus(value);
    this.fetchTransactionHistoryList();
  }

  resetFilterStatus(value: string) {
    BalanceController.setStatus(value);
  }

  // filter date
  get periodeStart() {
    return BalanceController.periodeStart;
  }

  get periodeEnd() {
    return BalanceController.periodeEnd;
  }

  setPeriode(value: Array<any>) {
    BalanceController.setPeriodeStart(value[0]);
    BalanceController.setPeriodeEnd(value[1]);
    value[0] && value[1] && this.fetchTransactionHistoryList();
  }

  get columns() {
    return [
      {
        name: "ID",
        styleHead: "w-100px text-left pt-4 text-xs md:text-base",
        render: (item: TransactionHistoryData) => {
          return `<div class="overflow-ellipsis text-left">${item.id}</div>`;
        }
      },
      {
        name: "Genesis Transaksi ID",
        styleHead: "w-400px text-left pt-4 text-xs md:text-base",
        render: (item: TransactionHistoryData) => {
          return `<div class="overflow-ellipsis text-left">${
            item.genesisTransactionId === "" ? "-" : item.genesisTransactionId
          }</div>`;
        }
      },
      {
        name: "Jumlah Top Up",
        styleHead: "w-52 text-left whitespace-nowrap pt-4 text-xs md:text-base",
        render: (item: TransactionHistoryData) => {
          return `<div class="overflow-ellipsis text-left text-green-lp-100">${formatPriceRP(
            item.totalAmount
          )}</div>`;
        }
      },
      {
        name: "Nama Bank",
        styleHead: "w-64 text-left whitespace-nowrap pt-4 text-xs md:text-base",
        render: (item: TransactionHistoryData) => {
          return `<div class="overflow-ellipsis text-left">${item.bankName}</div>`;
        }
      },
      {
        name: "Nama Rekening",
        styleHead: "w-52 text-left whitespace-nowrap pt-4 text-xs md:text-base",
        render: (item: TransactionHistoryData) => {
          return `<div class="overflow-ellipsis text-left">${item.accountName}</div>`;
        }
      },
      {
        name: "No. Rekening",
        styleHead: "w-64 text-left whitespace-nowrap pt-4 text-xs md:text-base",
        render: (item: TransactionHistoryData) => {
          return `<div class="overflow-ellipsis text-left">${item.accountNumber}</div>`;
        }
      },
      {
        name: "Tanggal Transaksi",
        styleHead: "w-52 text-left whitespace-nowrap pt-4 text-xs md:text-base",
        render: (item: TransactionHistoryData) => {
          return `<div class="flex flex-col"><div class="overflow-ellipsis text-left">${formatDateWithoutTime(
            item.createdAt
          )}</div>
          <div class="truncate text-gray-lp-600 w-32">${
            item.createdBy
          }</div></div>`;
        }
      },
      {
        name: "Status",
        styleHead: "w-36 text-left whitespace-nowrap text-xs md:text-base",
        render: (item: TransactionHistoryData) => {
          let style = "";
          switch (item.status.toLowerCase()) {
            case "approved":
              style = "text-green-lp-100 bg-green-lp-300";
              break;
            case "rejected":
            case "verification_rejected":
              style = "text-red-lp-500 bg-red-lp-600";
              break;
            case "pending":
              style = "text-gray-lp-500 bg-gray-lp-400";
              break;

            default:
              style = "text-yellow-lp-100 bg-yellow-lp-200";
              break;
          }
          return `<div class="capitalize overflow-ellipsis rounded-xl px-2 py-0 text-left font-medium ${style}">
              ${item.status.split("_").join(" ")}
            </div>`;
        }
      },
      {
        name: "Alasan Penolakan",
        styleHead:
          "w-44 lg:w-400px text-left whitespace-nowrap text-xs md:text-base",
        render: (item: TransactionHistoryData) => {
          return `<div class="overflow-ellipsis text-left">${
            item.rejectedReason === ""
              ? "-"
              : ellipsisString(item.rejectedReason, 66)
          }</div>`;
        }
      }
    ];
  }

  onClickRow(item: any) {
    BalanceController.setTopupManualIdToDetail(item.id);
    BalanceController.setShowTopupTransactionHistory(false);
    BalanceController.setShowRequestTopupManualDetail(true);
  }

  get isShowTransactionHistory() {
    return BalanceController.showTopupTransactionHistory;
  }
  showModalRequestTopupManualList(val: boolean) {
    BalanceController.setShowTopupTransactionHistory(val);
  }
}
